import React from 'react';

const UnderlinedButton = ({ href, children }) => {
  const handleClick = (event) => {
    event.preventDefault();
    const targetElement = document.querySelector(href);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <a href={href} className="underlined-button" onClick={handleClick}>
      {children}
    </a>
  );
};

export default UnderlinedButton;