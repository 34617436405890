import React, { useState, useEffect, useRef } from 'react';
import ServiceCard from './ServiceCard';
import InfoBox from './InfoBox';
import '../css/main.css';
import '../css/services.css';

const cardWidthGlobal = 411; // Breite einer Card
const gapWidthGlobal = 25; // Gap zwischen den Cards
const headerHeight = 54; // Höhe des Headers

const CardGrid = () => {
  const [cards, setCards] = useState([]);
  const [infoBoxVisible, setInfoBoxVisible] = useState(false);
  const [lastClickedCardIndex, setLastClickedCardIndex] = useState(null);
  const [infoBoxPosition, setInfoBoxPosition] = useState(null);
  const infoBoxRef = useRef(null);

  useEffect(() => {
    fetch('/json/services.json')
      .then(response => response.json())
      .then(data => setCards(data))
      .catch(error => console.error('Error loading cards:', error));
  }, []);

  const handleCardClick = (index, title) => {
    setInfoBoxVisible(lastClickedCardIndex !== index || !infoBoxVisible);
    setLastClickedCardIndex(index);
  
    // Hide default text
    const infoBoxDeafaultText = document.getElementById('infoBoxDeafaultText');
    if (infoBoxDeafaultText) {
      infoBoxDeafaultText.style.display = 'none';
    }
  
    // Calculate the position of the InfoBox
    const cardGrid = document.getElementById('card-grid');
    const containerWidth = cardGrid.offsetWidth;
    const cardWidth = cardWidthGlobal; // Breite einer Card
    const gapWidth = gapWidthGlobal; // Zwischenraum zwischen den Cards
    const cardsPerRow = Math.floor(containerWidth / (cardWidth + gapWidth));
    const rowIndex = Math.floor(index / cardsPerRow);
    const lastCardInRowIndex = (rowIndex + 1) * cardsPerRow - 1;
    setInfoBoxPosition(Math.min(lastCardInRowIndex, cards.length - 1));
  };

  useEffect(() => {
    if (infoBoxVisible && lastClickedCardIndex !== null) {
      // Last card clicked
      const lastCard = cards[lastClickedCardIndex];
      // Set icon and title
      const iconContainer = document.getElementById('iconContainer');
      if (iconContainer) {
        const filename = lastCard.title.replace(/ /g, "-").toLowerCase();
        iconContainer.innerHTML = `
          <img src="./ressources/service-icons-3/${filename}.svg?${performance.now()}" alt="${lastCard.title} Icon">
          <h3>${lastCard.title}</h3>
        `;

        // Set text inside the InfoBox
        const infoBoxContent = document.getElementById('textContainer');
        if (infoBoxContent) {
          infoBoxContent.innerHTML = ` 
            <p>${lastCard.description}</p>
            <h3>Unsere Leistungen</h3>
            <p>${lastCard.leistungen}</p>
          `;
        }
      }

      // Scroll to the InfoBox with offset for header
      const infoBox = document.getElementById('infoBox');
      if (infoBox) {
        const infoBoxTop = infoBox.getBoundingClientRect().top + window.scrollY - headerHeight - 20;
        window.scrollTo({ top: infoBoxTop, behavior: 'smooth' });
      }
    }
  
    const handleResize = () => {
      // Überprüfe, ob eine InfoBox existiert und eine Karte zuvor angeklickt wurde
      const infoBox = document.getElementById("infoBox");
      if (!infoBox || lastClickedCardIndex === null) {
        return; // Keine Aktion erforderlich, wenn keine InfoBox vorhanden oder keine Karte zuvor angeklickt wurde
      }
  
      const index = lastClickedCardIndex;
      const cardGrid = document.getElementById('card-grid');
      const containerWidth = cardGrid.offsetWidth;
      const cardWidth = cardWidthGlobal; // Breite einer Card
      const gapWidth = gapWidthGlobal; // Gap zwischen den Cards
      const cardsPerRow = Math.floor(containerWidth / (cardWidth + gapWidth));
  
      const rowIndex = Math.floor(index / cardsPerRow);
      const lastCardInRowIndex = (rowIndex + 1) * cardsPerRow - 1;
      const targetCardIndex = Math.min(lastCardInRowIndex, cards.length - 1);
  
      // Verschiebt die InfoBox entsprechend der neuen Berechnung
      if (index <= lastCardInRowIndex && lastCardInRowIndex < cards.length - 1) {
        cardGrid.insertBefore(infoBox, cardGrid.children[targetCardIndex + 1]);
      } else {
        cardGrid.appendChild(infoBox);
      }
    };
  
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [infoBoxVisible, lastClickedCardIndex, cards]);

  return (
    <div id="card-grid" className="cardGrid">
      {cards.map((card, index) => (
        <React.Fragment key={index}>
          <ServiceCard
            title={card.title}
            description={card.description}
            leistungen={card.leistungen}
            onClick={() => handleCardClick(index, card.title)}
          />
          {index === infoBoxPosition && infoBoxVisible && (
            <InfoBox isVisible={infoBoxVisible} ref={infoBoxRef} />
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default CardGrid;