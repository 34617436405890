import React, { useState } from "react";
import axios from "axios";
import "../css/contact.css";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [status, setStatus] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`/api/send`, formData);
      setStatus(response.data.msg);
    } catch (error) {
      if (error.response && error.response.status === 429) {
        console.log("LOG: Zu viele Anfragen, versuchen Sie es später erneut.");
        setStatus("Zu viele Anfragen, versuchen Sie es später erneut.");
      } else {
        console.log("LOG: Nachricht konnte nicht gesendet werden.");
        setStatus("Error: Nachricht konnte nicht gesendet werden.");
      }
    }
  };

  return (
    <div className="kontakt-wrapper">
      <form className="form" onSubmit={handleSubmit}>
        <h2>Kontaktieren Sie uns!</h2>
        <div className="input-row">
          <input
            type="text"
            name="name"
            placeholder="Name"
            value={formData.name}
            onChange={handleChange}
            required
          />
          <input
            type="email"
            name="email"
            placeholder="E-mail"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <textarea
          name="message"
          placeholder="Nachricht"
          value={formData.message}
          onChange={handleChange}
          required
        />
        <div className="input-row">
          <button type="submit">Senden</button>
          {status && <p className="status-message">{status}</p>}
        </div>
      </form>
      <div className="kontakt-info">
        <h2>Kontaktdaten</h2>
        <p>
          <strong>Adresse: </strong>
          <br />
          Wiesdorfer Platz 11, <br />
          51373 Leverkusen{" "}
        </p>
        <p>
          <strong>Tel: </strong>
          <a className="link" href="tel:+4921460273713">
            +49 (0) 214 60273713
          </a>
        </p>
        <p>
          <strong>E-mail: </strong>
          <a className="link" href="mailto:info@de-fender.it">
            info@de-fender.it
          </a>
        </p>
      </div>
    </div>
  );
};

export default ContactForm;