import React, { useEffect, useRef } from 'react';
const arrowForward = '../ressources/arrow_forward.svg';

const ServiceCardMobile = ({ title, description, leistungen }) => {
  const cardWrapperRef = useRef(null);
  const cardElementRef = useRef(null);
  const fadeOutElementRef = useRef(null);
  const buttonElementRef = useRef(null);

  useEffect(() => {
    const buttonElement = buttonElementRef.current;
    const cardElement = cardElementRef.current;
    const fadeOutElement = fadeOutElementRef.current;
    const cardWrapper = cardWrapperRef.current;

    const handleButtonClick = () => {
      const isExpanded = cardElement.classList.contains('is-expanded');

      if (!isExpanded) {
        cardElement.classList.add('is-expanded');
        fadeOutElement.classList.add('is-expanded');
        buttonElement.classList.add('is-expanded');

        const offset = -50; // 50px offset for the header
        const elementPosition = cardElement.getBoundingClientRect().top + window.pageYOffset;
        const offsetPosition = elementPosition + offset;

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        });
      } else {
        cardElement.classList.remove('is-expanded');
        fadeOutElement.classList.remove('is-expanded');
        buttonElement.classList.remove('is-expanded');

        const offset = -50; // 50px offset for the header
        const elementPosition = cardWrapper.getBoundingClientRect().top + window.pageYOffset;
        const offsetPosition = elementPosition + offset;

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        });
      }
    };

    buttonElement.addEventListener('click', handleButtonClick);

    return () => {
      buttonElement.removeEventListener('click', handleButtonClick);
    };
  }, []);

  return (
    <div className="card-wrapper" data-js-card ref={cardWrapperRef}>
      <div className="mobile-service-card" ref={cardElementRef}>
        <div className="header">
          <h3>{title}</h3>
        </div>
        <div className="body">
          <p>{description}</p>
          <h3>Unsere Leistungen</h3>
          <p>{leistungen}</p>
        </div>
        <div className="fade-out" ref={fadeOutElementRef}></div>
        <button className="mobile-mehr-button" ref={buttonElementRef}>
          <p>Mehr erfahren</p>
          <img src={arrowForward} alt="arrow_forward" />
        </button>
      </div>
    </div>
  );
};

export default ServiceCardMobile;