import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import '../css/main.css';
import '../css/landing.css';
import DevBanner from './DevBanner';

const menuIcon = '/ressources/menu.svg';
const logo = '/ressources/logo_text.svg';

function Header() {
  const [sideMenuOpen, setSideMenuOpen] = useState(false);

  useEffect(() => {
    const header = document.getElementById('header-nav-animated');
    const logo = document.getElementById('logo');
    const logoWrapper = document.getElementById('logo-wrapper');
    const ul = document.querySelector('.header-ul');

    const handleScroll = () => {
      if (window.scrollY > 50) {
        logo.style.height = '30px';
        header.style.backgroundColor = '#333';
        logoWrapper.classList.remove('tb-margin');
        ul.style.display = 'flex';
      } else if (window.scrollY <= 50 && !sideMenuOpen) {
        logo.style.height = '50px';
        header.style.backgroundColor = 'transparent';
        logoWrapper.classList.add('tb-margin');
        ul.style.display = 'none';
      }
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('load', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('load', handleScroll);
    };
  }, [sideMenuOpen]);

  const toggleMenu = () => {
    setSideMenuOpen(!sideMenuOpen);
  };

  const closeMenu = () => {
    setSideMenuOpen(false);
  };

  return (
    <>
      <header id="header-nav-animated" style={{ position: 'fixed', top: 0, width: '100%', zIndex: 1000 }}>
        <div className="logo lr-margin tb-margin" id="logo-wrapper">
          <a href="/">
            <img src={logo} alt="Logo" id="logo" />
          </a>
        </div>

        <nav className="lr-margin">
          <ul className="header-ul">
            <button className="hamburger-button" onClick={toggleMenu}>
              <img className="hamburger-button" src={menuIcon} alt="Hamburger menu button" />
            </button>
            <li><a href="/#service-section">Services</a></li>
            <li><Link to="/kontakt">Kontakt</Link></li>
            <li><Link to="/impressum">Impressum</Link></li>
            <li><Link to="/datenschutz">Datenschutz</Link></li>
          </ul>
        </nav>
      </header>
      <div id="side-menu" className="side-menu" style={{ width: sideMenuOpen ? '250px' : '0' }}>
        <ul>
          <li><a href="/#service-section">Services</a></li>
          <li><Link to="/kontakt">Kontakt</Link></li>
          <li><Link to="/impressum">Impressum</Link></li>
          <li><Link to="/datenschutz">Datenschutz</Link></li>
        </ul>
      </div>
      <div className="overlay" id="overlay" style={{ display: sideMenuOpen ? 'block' : 'none' }} onClick={closeMenu}></div>

      <DevBanner />
    </>
  );
}

export default Header;