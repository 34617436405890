import React from "react";
import CatchphraseBlock from "../components/CatchphraseBlock";
import UnderlineHeadline from "../components/UnderlineHeadline";

import CardGrid from "../components/CardGrid";
import StatCards from "../components/statCard";
import StatGrid from "../components/StatGrid";

import UeberUns from "../components/UeberUns";
import CardGridMobile from "../components/CardGridMobile";

const serverGrafik = "/ressources/Server_grafik_animated-smooth-bg.svg";

const Index = () => {
  return (
    <div>
      <main className="has-margin-top-small">
        <h1 className="hidden">DeFenderIT GmbH</h1>
      <CatchphraseBlock />

        <section className="Statistiken lr-margin" id="statistik-section">
          <UnderlineHeadline color="#ff3333">
            Die Zahlen sprechen für sich
          </UnderlineHeadline>
          <div className="Stats">
            <img
              className="server-grafik"
              src={serverGrafik}
              alt="Die Grafik zeigt ein Diagramm mit vier Statistiken zur Cybersicherheit für Unternehmen:

                        250.000+: Neue Schadprogrammvarianten pro Tag
                        203 Milliarden Euro: Schaden pro Jahr durch Angriffe auf deutsche Unternehmen
                        21.000+: Infizierte Systeme täglich
                        24.000+: Schwachstellen im Jahr 2023
                        46%: Der Deutschen Unternehmen wurden in den letzten 12 Monaten Opfer von Cyberattacken."
              loading="lazy" 
            />
            <StatGrid children={<StatCards />}></StatGrid>
          </div>
        </section>
        <section className="Services lr-margin tb-margin" id="service-section">
          <UnderlineHeadline color="#378910">Unsere Services</UnderlineHeadline>
          <CardGrid/>

          <CardGridMobile/>
        </section>
        <UeberUns />
      </main>
    </div>
  );
};

export default Index;
